import { formatTimestamp } from "./editsub";
import type { Node, FormatOptions, Sub, Cue } from "../types/node";

export class Formatter {
  private options: FormatOptions;
  private isVTT: boolean;
  private isTXT: boolean;
  private hasReceivedHeader: boolean;
  private index: number;

  constructor(options: FormatOptions) {
    this.options = options;
    this.hasReceivedHeader = false;
    this.isVTT = options.format === "WebVTT";
    this.isTXT = options.format === "TXT";
    this.index = 1;
  }

  public format(node: Node): string {
    let buffer = "";

    if (node.type === "header" && this.isVTT) {
      this.hasReceivedHeader = true;
      buffer += `${node.data}\n\n`;
    }

    if (["cue", "sub"].includes(node.type)) {
      if (!this.hasReceivedHeader && this.isVTT) {
        this.hasReceivedHeader = true;
        buffer += "WEBVTT\n\n";
      }

      buffer += this.formatData(node.data as Sub | Cue, this.index++, this.options);
    }

    return buffer;
  }

  

  private formatData(data: Cue | Sub, index: number, options: FormatOptions) {

    const text = options.isTranslated ? options.merged
        ?  data.text + "\n" + (data as Sub).translation
        : (data as Sub).translation : data.text;
 
    if (this.isTXT) {
      return text + "\n\n";
    }

    return [
      `${index > 1 ? "\n" : ""}${index}`,
      `${formatTimestamp(data.start, options)} --> ${formatTimestamp(
        data.end,
        options
      )}${
        options.format === "WebVTT" && data.settings
          ? " " + data.settings
          : ""
      }`,text
     ,
      "",
    ].join("\n");
  }
}
