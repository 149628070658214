import { default as auth3CMxZKAS5hMeta } from "/Users/khongaica/Projects/editsub/pages/auth.vue?macro=true";
import { default as conversation3HcjW8zv5PMeta } from "/Users/khongaica/Projects/editsub/pages/conversation.vue?macro=true";
import { default as dashboardM39HyB4nwQMeta } from "/Users/khongaica/Projects/editsub/pages/dashboard.vue?macro=true";
import { default as homehuKhaRlsz2Meta } from "/Users/khongaica/Projects/editsub/pages/home.vue?macro=true";
import { default as indexjFRpYtGu43Meta } from "/Users/khongaica/Projects/editsub/pages/index.vue?macro=true";
import { default as settingsBhszfOF0lEMeta } from "/Users/khongaica/Projects/editsub/pages/settings.vue?macro=true";
import { default as transcriptionstTkAEqxSXuMeta } from "/Users/khongaica/Projects/editsub/pages/transcriptions.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: auth3CMxZKAS5hMeta || {},
    component: () => import("/Users/khongaica/Projects/editsub/pages/auth.vue")
  },
  {
    name: "conversation",
    path: "/conversation",
    component: () => import("/Users/khongaica/Projects/editsub/pages/conversation.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/Users/khongaica/Projects/editsub/pages/dashboard.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homehuKhaRlsz2Meta || {},
    component: () => import("/Users/khongaica/Projects/editsub/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjFRpYtGu43Meta || {},
    component: () => import("/Users/khongaica/Projects/editsub/pages/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/Users/khongaica/Projects/editsub/pages/settings.vue")
  },
  {
    name: "transcriptions",
    path: "/transcriptions",
    meta: transcriptionstTkAEqxSXuMeta || {},
    component: () => import("/Users/khongaica/Projects/editsub/pages/transcriptions.vue")
  }
]