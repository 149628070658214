import { Formatter } from "../utils/Formatter";
import { formatTimestamp, parseTimestamp, parseSync } from "../utils/editsub";
import type { NodeList, Format,FormatOptions } from "../types/node";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      editsub: {
        formatTimestamp: (
          timestamp: number,
          options?: FormatOptions
        ): string => {
          return formatTimestamp(timestamp, options);
        },

        parseTimestamp: (timestamp: string): number => {
          return parseTimestamp(timestamp);
        },

        parseSync: (subtitle: string): NodeList => {
          const nodes = parseSync(subtitle);
          return nodes.map((node) => {
            if (node.type === "cue") {
              return {
                type: "sub",
                data: {
                  ...node.data,
                  translation: "",
                },
              };
            } else {
              return node;
            }
          });
        },

        stringifySync: (list: NodeList, options: FormatOptions): string => {
          const formatter = new Formatter(options);

          return list.reduce((buffer, node) => {
            return buffer + formatter.format(node);
          }, "");
        },

        parseExtension: (format: Format) :string => {
          switch (format) {
            case 'SRT':
              return 'srt'
            case 'WebVTT':
              return 'vtt'
            case 'TXT':
              return 'txt'
            default:
              return 'srt'
          }
        },
      },
    },
  };
});
